import '../styles/rainbow-kit.css'
import React, { useContext } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql, PageProps } from 'gatsby'
import { NftPageQuery, Strapi__Component_Meta_Metadata, Strapi_Global } from 'gatsby-graphql'
import { ThemeProvider, Container, NoSsr } from '@mui/material'
import { useLocalizePage } from '@/utils/hooks'
import ThemeTypeContext from '@/components/themeTypeContext'
import { IPageContext } from '@/types/pages'
import { theme, v2theme } from 'src/theme/ThemeProvider'
import MintWidget from '@/components/elements/mint-widget'
import { HeroSection, PerksSection, AboutSection, TableSection } from '@/components/sections/nft-v2/views'
import { Global } from '@emotion/react'
import { PerkFeatureSection } from '@/components/sections/nft-v2/views/perk-feature-section-view/perk-feature-section'
import { TermAndCondition } from '@/components/sections/nft-v2/views/term-and-condition-view/term-and-condition'
import { FooterNoteView } from '@/components/sections/nft-v2/views/footer-notes-view/footer-note-view'

const NftV2: React.FC<PageProps<NftPageQuery>> = (props) => {
  const { setTheme } = useContext(ThemeTypeContext)
  setTheme('core')

  React.useEffect(() => {
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      const handleResize = () => {
        document.body.style.display = 'none'
        document.body.offsetHeight
        document.body.style.display = ''
      }
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  const data = props?.data
  const pageContext = props?.pageContext as IPageContext
  const location = props?.location

  const metaData = data?.strapiNft?.MetaData as Strapi__Component_Meta_Metadata
  const global = data?.strapiGlobal as Strapi_Global

  useLocalizePage(pageContext, location)

  return (
    <>
      <SEO metaData={metaData} global={global} />

      <Layout
        global={global}
        pageContext={{ ...pageContext, ...metaData }}
        theme={theme}
        sx={{ padding: { sx: 0 }, maxWidth: 'none', m: 0, p: 0, marginTop: 0 }}
        version="v2"
      >
        <Global
          styles={() => ({
            body: {
              backgroundColor: '#fff',
              color: '#0C111F',
              overscrollBehavior: 'none',
              overflowAnchor: 'none',
            },
          })}
        />

        <ThemeProvider theme={v2theme}>
          <>
            <Container maxWidth="xl" disableGutters>
              <HeroSection contents={data?.strapiNft.Nft} />
              <PerksSection
                contents={props.data.strapiNft.NftPerk}
                drawerContents={data.strapiNft.NftMoreInformation}
                cashbackContent={data.strapiNft}
              />
            </Container>

            <AboutSection contents={data.strapiNft.NftAbout} />

            <Container maxWidth="xl">
              <PerkFeatureSection contents={data?.strapiNft.NftFeaturePerk} />
              <TableSection contentHeader={data?.strapiNft.NftTableInformation} contents={data?.strapiNft.NftTable} />
            </Container>

            <Container maxWidth="xl" disableGutters>
              <MintWidget contentV1={data?.strapiNft.MintSection} contentV2={data.strapiNft.NftMintV2} />
            </Container>
            <Container maxWidth="md">
              <TermAndCondition content={data.strapiNft.NftTermsOfService} />
            </Container>
            <Container maxWidth="lg">
              <FooterNoteView contents={data.strapiNft.NftFooternotes} />
            </Container>
          </>
        </ThemeProvider>
      </Layout>
    </>
  )
}

export default NftV2

export const query = graphql`
  query NftPage($locale: String) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiNft(locale: { eq: $locale }) {
      Nft {
        Title
        FirstParagraph {
          data {
            FirstParagraph
          }
        }
        VideoUrl
        SecondParagraph {
          data {
            SecondParagraph
          }
        }
        VimeoUrl
        ShowCountDown
        CountDownLiveTime
        CountDownOfferText
        CountDownLiveText
      }
      NftTable {
        NftTableRow {
          PopupData {
            ShowPopUp
            Heading
            Content {
              data {
                Content
              }
            }
          }
          NftHoldersColumnIconValue
          NftHoldersColumnTextValue
          RowBackground
          RowTitle {
            data {
              RowTitle
            }
          }
          UsdSubscribersColumnIconValue
          UsdSubscribersColumnTextValue
          TextAlignment
        }
        NftTableRowFirstColumn {
          NftHoldersTextValue
          Title
          UsdSubscribersTextValue
        }
        OptionalTableFooter {
          ShowTableFooter
          TableFooterList {
            Text
          }
        }
      }
      NftPrices {
        Title {
          data {
            Title
          }
        }
        ColumnOneTitle
        ColumnTwoTitle
        Row {
          Left
          Right
        }
      }
      NftTermsOfService {
        title
        Content {
          data {
            Content
          }
        }
      }
      MintSection {
        Title
        WalletButtonText
        MintButtonText
        DisconnectButtonText
        TosParagraphPreLink
        TosParagraphLinkText
        TosParagraphPostLink
        InsufficientBalanceError {
          data {
            InsufficientBalanceError
          }
        }
        UnexpectedError {
          data {
            UnexpectedError
          }
        }
        NotOnWhitelistError {
          data {
            NotOnWhitelistError
          }
        }
        WrongBlockchainError {
          data {
            WrongBlockchainError
          }
        }
        MintingPausedError {
          data {
            MintingPausedError
          }
        }
        SoldOutError {
          data {
            SoldOutError
          }
        }
        WalletError {
          data {
            WalletError
          }
        }
        MintingConfirmationAlertTitle
        MintingConfirmationAlertText
        WaitingOnTxAlertTitle
        WaitingOnTxAlertText
        NftMintColumn {
          Number
          Text
        }
        Labels {
          Minted
          Remaining
          CurrentPrice
          SwitchChain
          Disconnect
          Connect
        }
      }
      MetaData {
        MetaTitle
        MetaDescription
        ShareImage {
          localFile {
            publicURL
          }
        }
      }
      NftPerk {
        title
        description
        adsTitle
        adsDescription
        perks {
          Content {
            data {
              Content
              id
            }
          }
        }
        informationButton {
          text
          type
        }
        mobileBackgroundImage {
          localFile {
            publicURL
          }
        }
        desktopBackgroundImage {
          localFile {
            publicURL
          }
        }
        video
      }
      NftAbout {
        title
        video
        icon {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        background {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      NftFeaturePerk {
        title
        description
        steps {
          Text {
            data {
              id
              Text
            }
          }
        }
        cards {
          title
          description
          id
          icon {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
      NftTableInformation {
        title
        description
      }
      NftFooternotes {
        title
        steps {
          Content {
            data {
              Content
              id
            }
          }
        }
      }
      NftMintV2 {
        title
        tile {
          title
          image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        nftPriceAndRange {
          range
          value
          id
          hidePrice
        }
        mintTermAndCondition {
          data {
            mintTermAndCondition
          }
        }
        connectWalletTermAndCondition {
          data {
            connectWalletTermAndCondition
          }
        }
        backgroundImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      NftMoreInformation {
        topImportantAlert
        topImportantAlertDescription {
          data {
            topImportantAlertDescription
          }
        }
        cashbackTitle
        cashbackDescription
        cashbackMoreInformation {
          data {
            cashbackMoreInformation
          }
        }
        NftPriceAndCashbackTable {
          range
          value
        }
        NftCashbackTableHeader {
          title
          value
        }
        AfterPurchasingTitle
        AfterPurchasingSteps1 {
          title {
            data {
              title
            }
          }
          description {
            data {
              description
            }
          }
          importantNote {
            data {
              importantNote
            }
          }
          linkDescription {
            data {
              linkDescription
            }
          }
        }
        links {
          id
          link
          image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        AfterPurchasingSteps2 {
          title {
            data {
              title
            }
          }
          description {
            data {
              description
            }
          }
        }
        AfterPurchasingSteps3 {
          title {
            data {
              title
            }
          }
          description {
            data {
              description
            }
          }
        }
        AfterPurchasingSteps4 {
          title {
            data {
              title
            }
          }
          description {
            data {
              description
            }
          }
          importantNote {
            data {
              importantNote
            }
          }
          linkDescription {
            data {
              linkDescription
            }
          }
        }
        TermAndCondition
        SequenceTermAndCondition {
          Content {
            data {
              Content
            }
          }
        }
        bottomImportantAlert
        bottomImportantAlertDescription
      }
      CashbackFAQ {
        id
        question
        answer {
          data {
            answer
          }
        }
      }
      cashbackFAQTitle
      cashbackFAQDescription
    }
  }
`
